<template>
  <v-app>
    <dashboard-core-app-bar />
    <dashboard-core-staffdrawer />
    <dashboard-core-homedrawer />
    <dashboard-core-agentdrawer />
    <dashboard-core-view />
  </v-app>
</template>

<script>

export default {
  name: 'DashboardIndex',
  components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreStaffdrawer: () => import('./components/core/StaffNav'),
      DashboardCoreHomedrawer: () => import('./components/core/HomeOwnerNav'),
      DashboardCoreAgentdrawer: () => import('./components/core/AgentNav'),
      DashboardCoreView: () => import('./components/core/View'),
  },
   data: () => ({
      expandOnHover: false,
    }),
  };
</script>
